/* Header.css */

.header {
    display: flex;
    padding: 10px 20px;
    background-color: black;
    justify-content: space-between;
    padding: 10px 20px;
}

.div-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.language-select,
.country-select {
    color: black;
    font-weight: bold;
}

.sign-in {
    align-self: center;
    color: white;
    border-radius: 4px;
    background-color: #6A0DAD;
    color: #fff;
    height: 45px;
    width: 80px;
    font-weight: bold;
}

label {
    margin: 5px;
    color: white;

}

.auth {
    align-items: center;
    border-radius: 4px;
    background-color: #6A0DAD;
    color: #fff;
    height: 45px;
    width: 120px;
    font-weight: bold;
}

select {
    padding: 5px;
    border: none;
    border-radius: 4px;
    background-color: #6A0DAD;
    /* Darker purple for select */
    color: #fff;
    outline: none;
    height: 45px;
}

/* Style select dropdown arrow */
select::-ms-expand {
    display: none;
}


/* Style select dropdown arrow for Firefox */
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}