.container-table-header {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    height: auto;
    height: 6vh;
    justify-content: center;
}

.centered-content {
    height: 30px;
}
