/* src/Calendar.css */

.calendar-container {
  text-align: center;
}

.calendar-header {
  margin: 20px;
  font-size: 24px;
}

.calendar-button {
  background-color: #6a0dad;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: bold;
  margin: 20px;
  border-radius: 4px;
  height: 50px;
}

.divFooter {
  height: 90px;
}

.calendar-table {
  width: 80%;
  border-collapse: collapse;
  margin-top: 20px;
  margin: 0 auto;
}

.calendar-table th,
.calendar-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.calendar-table th {
  background-color: #f2f2f2;
}

.div-navigate-months {
  display: flex;

  overflow: hidden;
  text-align: center;
  justify-content: center;
}
.div-navigate-months p {
  margin: 10px;
}

.calendar-checkbox {
  transform: scale(1.5);
}

.past-button {
  background-color: transparent;
  color: "transparent";
  border: none;
  margin: 5px;
}

.past-button img {
  width: 30px;
  background-color: transparent;
}

.next-button img {
  width: 30px;
  color: "#0066FF";
}

.next-button {
  background-color: transparent;
  /* Set the background color to transparent */
  border: none;
  cursor: pointer;
  color: transparent;
}
