/* styles.css */

.auth-container {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  width: 34%;
}

.container_form {
  display: flex;
  width: 50%;
}

.auth-box {
  background-color: #f5f5f5;
  padding: 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 70%;
}

.create_account {
  margin: 5px;
}

.create_account_to_link {
  color: #6a0dad;
  margin: 5px;
  font-weight: 700;
  text-decoration: none;
}

.auth-title {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 700;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signin-button {
  background-color: #6a0dad;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
}

.social-connect-button {
  color: black;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.social-connect-button img {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.social-connect-button span {
  padding: 0 5px;
}
@media (max-width: 768px) {
  .auth-container {
    display: block;
  }

  .logo-container,
  .container_form {
    width: 100%;
  }

  .auth-box {
    width: 100%;
  }
}
